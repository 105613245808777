import Home from "@/components/home/Home";
import { store } from "@/store";
import { Provider } from "react-redux";

const Index = () => {
    return <Provider store={store}>
        <Home page={"companies"} />;
    </Provider>
};

export default Index;
